<template>
  <v-app-bar color="white" white fixed hide-on-scroll app>
    <v-toolbar-title class="pl-0" @click="goHome">
      <div class="d-flex align-center">
        <!-- Image width Size : 50px -->
        <!-- <img src="/svg/otc_logo.svg" -->
        <img src="/svg/logo2201.svg" width="90px" class="ml-2" />
      </div>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <!-- <div>version {{app_version}}</div> -->
    <!-- <v-btn > -->
    <!-- <svg v-if="!isLogedIn" class="align-self-center" style="width:28px;height:28px" viewBox="0 0 24 24" @click="login">
      <path fill="#a8a8a8"
        d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,6A2,2 0 0,0 10,8A2,2 0 0,0 12,10A2,2 0 0,0 14,8A2,2 0 0,0 12,6M12,13C14.67,13 20,14.33 20,17V20H4V17C4,14.33 9.33,13 12,13M12,14.9C9.03,14.9 5.9,16.36 5.9,17V18.1H18.1V17C18.1,16.36 14.97,14.9 12,14.9Z" />
    </svg> -->
    <!-- <img v-if="!isLogedIn" @click="login"
            src="https://res.cloudinary.com/dw4yrr8eq/image/upload/v1649656398/w.d_login_igf2k2.svg" 
            width="24px" class="ml-2"
        /> -->
    <!-- </v-btn>  -->
    <img
      v-if="!isLogedIn"
      src="/imgs/login.png"
      width="40px"
      class="ml-2"
      style="cursor: pointer"
      @click="login"
    />
  </v-app-bar>
</template>

<script>
// import { mdiAccount } from '@mdi/js';

export default {
  name: "AppBar",
  data() {
    return {
      app_version: "1",
      //    isLogedIn : false,
    };
  },

  computed: {
    isLogedIn() {
      let result = false;
      let flag = this.$store.getters.getUserData;
      if (flag) result = true;
      return result;
    },
  },

  mounted() {},

  methods: {
    goHome() {
      this.$router.push("/home/home");
      this.$store.commit({
        type: "setCurrentRoute",
        route: "/home",
      });
    }, // goHome

    login() {
      this.$store.commit({ type: "setSubPage", sub_page: "로그인" });
      this.$router.push("/sign/login");
      this.$store.commit({
        type: "setCurrentRoute",
        route: "login",
      });
    }, // login
  },
};
</script>

<style lang="scss" scoped>
.v-toolbar__title {
  font-size: 1rem !important;
  color: #969696;
}
// mixin
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-app-bar {
  position: relative;
  width: 100%;
  height: 60px;
  margin: 0 auto;
  padding: 0 10px;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05) !important;

  .logo {
    width: auto;
    height: auto;
  }

  .profile {
    width: auto;
    height: auto;
  }

  &::v-deep .v-toolbar__content {
    padding: 0;
  }
}
</style>
